<template>
    <div class="chart-wrapper">
        <canvas style="touch-action: none; height: 200px;" id="chart"></canvas>
    </div>
</template>

<script>
const { InfluxDB } = require('@influxdata/influxdb-client')
const token = 'pwwKKhiuR_4CAmrbZEMC2xgMurHejJPpD4hyCED_LvwtlGLhTeoxtOnkInSrJ-84g73pR6NSNMEuHumxdDXozA=='
const org = 'OceanSense'
const client = new InfluxDB({ url: 'https://us-central1-1.gcp.cloud2.influxdata.com', token: token })
const queryApi = client.getQueryApi(org)
import { Chart } from 'chart.js'

export default {
	name: 'Graph',
	props: ['id'],
	data() {
		return {
			chart_data: [],
            timestamps: [],
			chart: null,
		}
	},
	mounted() {
		this.getData();
	},
	methods: {
        getData() {
			if (this.chart) { this.chart.destroy() }
			this.chart_data = [];
			this.timestamps = [];
            let fluxQuery =  `from(bucket: "${this.id}") 
                |> range(start: -7d)
                |> filter(fn: (r) => r["_measurement"] == "http")
                |> filter(fn: (r) => r["_field"] == "externalTemperature")
                |> aggregateWindow(every: 10m, fn: mean, createEmpty: true)
                |> yield(name: "temp")`

            queryApi.queryRows(fluxQuery, { 
                next: (row, tableMeta) => {
                    const o = tableMeta.toObject(row)
                    this.chart_data.push(o._value);
                    this.timestamps.push(new Date(o._time));
                },
                error: (error) => {
                    console.log('Influx error: ', error);
                },
                complete: () => {
                    this.initChart();
                }
            });
        },
		initChart() {
			const ctx = document.getElementById('chart').getContext("2d");
			this.chart = new Chart(ctx, {
				type: 'line',
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						display: false,
					},
					scales: {
						xAxes: [{
							display: true,
							type: 'time',
							distribution: 'linear',
							time: {
								unit: 'day',
								unitStepSize: 1,
							},
							ticks: {
								display: true,
								fontSize: 12,
								autoSkip: true,
								maxTicksLimit: 7,
								maxRotation: 0,
							},
							gridLines: {
								display: true,
								color: "rgba(241, 241, 241, 1)",
								zeroLineColor: 'rgba(241, 241, 241, 1)',
							},
						}],
						yAxes: [{
							display: true,
							ticks: {
								display: true,
								fontSize: 12,
								autoSkip: true,
								maxTicksLimit: 5,
							},
							gridLines: {
								color: "rgba(241, 241, 241, 1)",
							} 
						}],
					},
					hover: {
						mode: 'nearest',
						intersect: 'false',
					},
					tooltips: {
                        displayColors: false,
                        enabled: true,
                        mode: 'nearest',
                        intersect: false,
                        // filter: function (tooltipItem) {
                        //     return tooltipItem.datasetIndex === 1;
                        // },
                        callbacks: {
                            label: (tooltipItem, data) => {
                                let xlabel = tooltipItem.xLabel;
                                let ylabel = `${tooltipItem.yLabel.toFixed(2)}`;
                                return [ylabel, xlabel]
                            },
                            title: function(tooltipItem, data) {
                            	return;
                            }
                        }
                    }
				},
				data: {
					labels: this.timestamps,
					datasets: [
						{
							label: 'Temperature',
							data: this.chart_data,
							lineTension: 0.2,
							radius: 0,
							pointRadius: 0,
							hoverRadius: 4,
							fill: false,
							borderColor: 'rgb(0,148,255)',
							borderWidth: 3,
							spanGaps: false,
						},
					],
				}	
			});
		},
	}
}
</script>

<style scoped>


</style>
