<template>
	<div style="margin: 10px;">
		<div class="top-wrapper">
			<div class="player-wrapper">
				<Player :id="id" :recording="recording" ref="Player"/>
			</div>
			<div class="controls">
				<div class="box" style="padding: 0px;">
					<div class="control">
						<b-button icon-left="lightbulb-on-outline" type="is-medium" disabled></b-button>
					</div>
					<div class="control">
						<b-button icon-left="wiper" type="is-medium" disabled></b-button>
					</div>
					<div class="control">
						<b-button icon-left="image-outline" type="is-medium" @click="snapshot()"></b-button>
					</div>
					<div class="control">
						<b-button icon-left="record-rec" type="is-medium" :class="{ active: recording }" @click="startStopRecord()"></b-button>
					</div>
					<div class="control">
						<b-button icon-left="overscan" type="is-medium" @click="fullscreen()"></b-button>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-wrapper">
			<div class="box">
				<div class="header header-container">
					<nav class="level">
						<div class="level-left">
							<div class="level-item">
								<div>
									<b-button icon-left="thermometer" @click="selected='temp'"
										:type="selected === 'temp' ? 'is-primary' : ''">
											Temperature 
											<span style="padding-left: 20px" class="temp">{{ temp }}
												<span class="unit"> °C</span>
											</span>
									</b-button>
								</div>
							</div>
							<div class="level-item">
								<div>
									<b-button icon-left="motion" @click="selected='motion'"
										:type="selected === 'motion' ? 'is-primary' : ''">
											Motion 
											<span style="padding-left: 20px">{{ motion }}</span>
									</b-button>
								</div>
							</div>
							<div class="level-item">
								<div style="text-align: left;">
									<b-dropdown aria-role="list" v-model="index">
										<template #trigger="{ active }">
											<b-button 
												@click="selected='object'"
												icon-left="feature-search" 
												:icon-right="active ? 'menu-up' : 'menu-down'"
												:type="selected === 'object' ? 'is-primary' : ''">
													Object 
													<span style="padding-left: 20px">{{ objects[index] }}</span>
											</b-button>
										</template>
										<div v-for="(item, index) in objects" :key="index" >
											<b-dropdown-item aria-role="listitem" :value="index">{{ item }}</b-dropdown-item>
										</div>
									</b-dropdown>
								</div>
							</div>
						</div>
					</nav>
				</div>
				<div class="graph-wrapper">
					<Graph :id="id"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Graph from '@/components/Graph.vue'
import Player from '@/components/Player.vue'

export default {
	name: 'Live',
    components: {
        Graph,
		Player,
    },
	data() {
		return {
			cleanup: false,
			temp: 29.3,
			selected: 'temp',
			motion: 'NO',
			objects: ['Manta Ray', 'Jaguar Shark', 'Humpback Whale'],
			index: 0,
			recording: false,
		}
	},
    computed: {
        id: function () {
            return this.$store.state.selected;
        },
    },
	methods: {
		fullscreen() {
			console.log('Requesting fullscreen')
			const video = document.getElementById(this.id);
			video.requestFullscreen();
		},
		startStopRecord() {
			if (!this.recording) { 
				console.log('Starting to record...')
				this.recording = true;
			} else {
				this.recording = false;
			}
			
		},
		snapshot() {
			this.$refs.Player.snapshot();
		},
	}
}
</script>

<style scoped>
.top-wrapper {
	position: relative;
	margin: auto;
	margin-bottom: 10px;
}
.bottom-wrapper {
	width: 100%;
	display: inline-block;
}
.button {
	font-size: 14px;
}
.graph-wrapper {
	margin-top: 20px;
	height: auto;
}
.temp {
	color: rgba(0, 227, 23);
}
.unit {
	font-size: 10px;
}
.controls {
	position: absolute;
	top: 0px;
	width: 50px;
	display: inline-block;
	margin-left: 10px;
}
.control {
	color: var(--dark-gray);
}
.control .button {
	background-color: none !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
}
.control .button:hover {
	color: var(--gray)
}
.player-wrapper {
	display: inline-block;
	max-width: 70%
}

.active {
	color: red !important;
}
</style>
