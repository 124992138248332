<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			
		}
	},
	mounted() {
		this.$router.push('/')
	},
	methods: {
		
	}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--background);
}
:root {
  --gray: #A9A9A9;
  --gray-dark: #696969;
  --background: #F1F1F1;
  --dark: #0D1A2C;
  --select: #00BEFD;
}
</style>
