<template>
  	<div class="wrapper"> 
		<div class="header header-container">
			<nav class="level">
				<div class="level-left">
					<div class="level-item">
						<div class="org">
							<b-button>{{ $store.state.user.org }}</b-button>
						</div>
					</div>
				</div>
				<div class="level-right">
					<div class="level-item">
						<div class="add-device">
							<b-button icon-left="plus-circle" @click="addUser()">Add User</b-button>
						</div>
					</div>
				</div>
			</nav>
		</div>
		<div class="tables-wrapper">
			<div class="users">
				<div class="tables">
					<b-table
						:data="users"
						:scrollable="false"
						:mobile-cards="true"
						hoverable>

						<b-table-column field="name" label="Name" v-slot="props" width="150">
							<div class="table-element">
								<b-icon icon="account" size="is-small" style="color: #696969"></b-icon> {{ props.row.name }}
							</div>
						</b-table-column>

						<b-table-column field="email" label="Email" v-slot="props" width="150">
							<div class="table-element">
								<b-icon icon="email" size="is-small" style="color: #696969"></b-icon> {{ props.row.email }}
							</div>
						</b-table-column>

						<b-table-column field="role" label="Role" v-slot="props" width="150">
							<div class="table-element">
								<!-- <b-field>
									<b-select :placeholder="props.row.role" icon="key" size="is-small" @input="changeRole(props)" :value="props.row.role">
										<option value="Admin" :disabled="props.row.role === 'Admin'">Admin</option>
										<option value="Viewer" :disabled="props.row.role === 'Viewer' || props.row.email === $store.state.user.email">Viewer</option>
									</b-select>
								</b-field> -->
								<div class="table-element">
									<b-icon icon="key" size="is-small" style="color: #696969"></b-icon> {{ props.row.role }}
								</div>
							</div>
						</b-table-column>

					</b-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { db } from '../main'
import firebase from 'firebase/app'

export default {
	name: 'Admin',
	data() {
		return {
			users: [],
		}
	},
	mounted() {
		this.getUsers();
	},
	computed: {
		users_filtered() {
            let data = [];
            this.users.forEach((user, index) => {
                data.push({
                    'id': index,
                    'name': user.name,
					'email': user.email,
                    'role': user.role,
                });
            })
            return data
        },
	},
	methods: {
		toast(msg, type) {
			this.$buefy.toast.open({
				message: msg,
				type: type,
			});
		},
		getUsers() {
			db.collection('Organizations').doc(this.$store.state.user.org).get().then(doc => {
				doc.data().Users.forEach(user => {
					db.collection('Users').doc(user).get().then(doc => {
						this.users.push({
							name: doc.data().name,
							org: doc.data().org,
							email: doc.data().email,
							role: doc.data().admin ? 'Admin' : 'Viewer',
						});
					});
				});
			});
		},	
		addUser() {
			this.$buefy.dialog.prompt({
                message: `Enter user's email`,
                inputAttrs: {
                    placeholder: 'email',
                },
                trapFocus: true,
                onConfirm: (email) => {
					db.collection('Organizations').doc(this.$store.state.user.org).update({
						Invited: firebase.firestore.FieldValue.arrayUnion(email),
					}).then(this.toast('User invited', 'is-success'));
				}
            });
		},
		changeRole(props) {
			props.row.role = 'Admin'
		}
	},
}
</script>

<style scoped>
.header {
	height: 60px;
	width: 100%;
}
.org .button {
	color: white;
	font-size: 14px;
	border-radius: 20px;
	border: none;
	background-color: #0D1A2C;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.add-device .button {
	color: black;
	font-size: 14px;
	border-radius: 20px;
	border: none;
	background-color: white;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.wrapper {
	margin: 20px;
}

.tables {
	text-align: left;
	font-size: 14px;
	cursor: pointer;
}

.tables-wrapper {
	float: left;
	width: 60%;
}



</style>
