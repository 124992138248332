import Vue from 'vue'
import firebase from 'firebase/app'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'

Vue.use(VueRouter)

import Dashboard from '@/components/Dashboard.vue'
import Devices from '@/components/Devices.vue'
import Admin from '@/components/Admin.vue'
import Live from '@/components/Live.vue'
import Grid from '@/components/Grid.vue'


const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: 'dashboard',
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: 'dashboard',
				component: Dashboard,

			},
			{
				path: 'live',
				component: Live,

			},
			{
				path: 'grid',
				component: Grid,

			},
			{
				path: 'devices',
				component: Devices,

			},
			{
				path: 'admin',
				component: Admin,

			},
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			requiresAuth: false,
		},
	},	
	{
		path: '/signup',
		component: SignUp,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/:catchAll(.*)",
		redirect: '/',
	},
]

const router = new VueRouter({
  mode: 'abstract', 
  base: "/",
  routes,
})

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	if (requiresAuth && !await firebase.getCurrentUser()) {
		router.push('login');
	} else {
		next();
	}
});

export default router
