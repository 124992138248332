<template>
	<div class="wrapper">
        <div class="wrapper-tile">
            <div class="box">
                <h1 class="fathom-title">Periscope</h1>
                <p style="font-size: 16px;">Fathom Ocean</p>
                <div class="block" id="dashboard" style="margin-top: 30px;">
                    <img src="../assets/periscope.png" width="80"/>
                </div>
                <section>
                    <b-field>
                        <b-input type="name" placeholder="Name"
                            v-model="form.name">
                        </b-input>
                    </b-field>
                    <b-field>
                        <b-input type="name" placeholder="Organization"
                            v-model="form.org">
                        </b-input>
                    </b-field>
                    <b-field>
                        <b-input type="email" placeholder="Email"
                            v-model="form.email">
                        </b-input>
                    </b-field>
                    <b-field>
                        <b-input type="password" placeholder="Password"
                            v-model="form.password"
                            @keyup.native.enter="submit">
                        </b-input>
                    </b-field>
                    <section style="text-align: center">
                        <b-button style="margin-left: 15px;" @click="signup" type="is-primary">Sign Up</b-button>
                        <div v-if="error" class="alert alert-danger" style="margin-top: 15px;">{{error}}</div>
                    </section>
                </section>
                <p class="sign-up">Already have an account? Click here to <router-link to="/login" style="color: black; font-weight: bold">Login.</router-link></p>
            </div>
        </div>
    </div>	
</template>

<script>
import firebase from 'firebase/app'
import { db } from '../main'
export default {
    name: 'SignUp',
    data() {
        return {
            form: {
                name: '',
                org: '',
                email: '',
                password: '',
            },
            error: null,
        }
    },
    methods: {
        signup() {
            db.collection('Organizations').doc(this.form.org).get().then(doc => {
                // Org does not exist, need to create it
                if (!doc.exists) {
                    if (this.form.email.split('@')[1] !== 'viewintotheblue.com') {
                        this.error = 'Sorry, you do not have permission to sign up at this time';
                    } else {
                        firebase
                            .auth()
                            .createUserWithEmailAndPassword(this.form.email, this.form.password)
                            .then((userCredential) => {
                                // Signed in 
                                let user = userCredential.user;
                                // Create User
                                db.collection('Users').doc(this.form.email).set({
                                    name: this.form.name,
                                    org: this.form.org,
                                    email: this.form.email,
                                    UID: user.uid,
                                    admin: true,
                                }, { merge: true });
                                // Add User to Org
                                db.collection('Organizations').doc(this.form.org).set({
                                    Devices: [],
                                    Users: [this.form.email],
                                }, { merge: true });
                                this.$store.state.user = {
                                    name: this.form.name,
                                    email: this.form.email,
                                    org: this.form.org,
                                    admin: true,
                                    UID: user.uid,
                                }
                                this.$router.push('Home');
                            })
                            .catch((error) => {
                                this.error = error.message;
                            });
                    }
                } else {
                    // Org already exists, check if User is allowed access
                    db.collection('Organizations').doc(this.form.org).get().then(doc => {
                        let invited = doc.data().Invited;
                        if (invited.includes(this.form.email)) {
                            firebase
                            .auth()
                            .createUserWithEmailAndPassword(this.form.email, this.form.password)
                            .then((userCredential) => {
                                // Signed in 
                                let user = userCredential.user;
                                // User is allowed, create user in org and remove from invited
                                db.collection('Organizations').doc(this.form.org).update({
                                    Users: firebase.firestore.FieldValue.arrayUnion(this.form.email),
                                    Invited: firebase.firestore.FieldValue.arrayRemove(this.form.email),
                                });
                                // Add User to User DB
                                db.collection('Users').doc(this.form.email).set({
                                    name: this.form.name,
                                    org: this.form.org,
                                    email: this.form.email,
                                    UID: user.uid,
                                    admin: false,
                                }, { merge: true });
                                this.$store.state.user = {
                                    name: this.form.name,
                                    email: this.form.email,
                                    org: this.form.org,
                                    admin: false,
                                    UID: user.uid,
                                }
                                this.$router.push('Home');
                            });
                        } else {
                            this.error = 'You do not have permission to sign up with this org. Please ask your admin for an invite.'
                        }
                    });
                }
            });
		},
    }
};
</script>

<style scoped>
.wrapper {
    width: 100%;
    height: 100vh;
    background: -webkit-linear-gradient(#0580D9 , #1A1A1A);
}
.wrapper-tile {
    margin: auto;
    padding-top: 70px;
    max-width: 400px;
}
.fathom-title {
    font-weight: bolder;
    font-size: 22px;
}
.sign-up {
    text-align: left;
    margin-top: 30px;
}
.button {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>