<template>
	<div>
		<div class="main-page">
			<section class="main-layout">
				<Sidebar/>
				<div class="app-wrapper">
					<router-view/>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Home',
  components: {
    Sidebar,
  }
}
</script>


<style>
.b-sidebar .sidebar-content.is-fullheight {
	background-color: var(--dark) !important;
}

.b-table .table {
	background-color: white;
}

.button.is-primary{ 
	background-color: var(--dark) !important;
}
.button {
	border-radius: 20px !important;
	border: none !important;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;
  	transition: all 0.3s cubic-bezier(.25,.8,.25,1) !important;
}
.box {
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
}
a.dropdown-item.is-active {
	background-color: var(--background) !important;
	color: black !important;
}
.input:active {
	border-color: var(--select) !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
}
.input:focus {
	border-color: var(--select) !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;

}.modal-card-foot .button {
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
}
.main-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
}
.main-layout {
	display: flex;
	flex-direction: row;
	min-height: 100%;
	width: 100%;
	/* min-height: 100vh; */
}

.app-wrapper {
	padding: 0px;
	width: 100%;
	/* max-width: 1024px; */
	margin-left: auto;
	margin-right: auto;
	overflow-x: hidden;
	overflow-y: hidden;
	/* background-color: $gray-light; */
}


</style>

