<template>
	<div style="margin: 20px;">
		<div class="header header-container">
			<nav class="level">
				<div class="level-left">
					<div class="level-item">
						<div class="all-devices">
							<b-button>All Devices</b-button>
						</div>
					</div>
				</div>
				<div class="level-right">
					<div class="level-item">
						<div class="add-device">
							<b-button icon-left="plus-circle" @click="addDevice()">Add Device</b-button>
						</div>
					</div>
				</div>
			</nav>
		</div>
		<div class="grid-container">
			<div v-for="(device, index) in devices" :key="index" >
				<Thumb class="thumb" :id="device" @click.native="select(device)"/>
			</div>
		</div>
	</div>
</template>

<script>
import { db } from '../main'
import Thumb from '@/components/Thumb.vue'
import firebase from 'firebase/app'

export default {
	name: 'Dashboard',
	components: {
		Thumb,
	},
	data() {
		return {
			devices: [],
			selected: null,
		}
	},
	mounted() {
		this.getDevices();
	},
	methods: {
		toast(msg, type) {
			this.$buefy.toast.open({
				message: msg,
				type: type,
			});
		},
		getDevices() {
			if (!this.$store.state.user) {
				this.$router.push('/login');
			} else {
				db.collection('Organizations').doc(this.$store.state.user.org).get().then(doc => {
					this.devices = doc.data().Devices;
					this.$store.state.devices = this.devices;
					this.$store.state.selected =  this.devices[0];
				});
			}
		},
		addDevice() {
			this.$buefy.dialog.prompt({
                message: `What is the Sensor ID?`,
                inputAttrs: {
                    placeholder: 'e.g. ORCA-XXXX',
                },
                trapFocus: true,
                onConfirm: (id) => {
					// Check if device is already added
					if (this.devices.includes(id)) {
						this.toast('This device already exists in your org', 'is-warning');
					} else {
						// Check if device belongs to different org first
						db.collection('Devices').doc(id).get().then(doc => {
							// Check if Sensor ID exists
							if (doc.exists) {
								// Device exists already in a different org
								if (doc.data().org && doc.data().org !== this.$store.state.user.org) {
									this.toast('You do not have permission to add this device', 'is-danger');
								} else {
									// Add device to org and update
									db.collection('Devices').doc(id).set({
										org: this.$store.state.user.org,
									}, {merge: true});
									db.collection('Organizations').doc(this.$store.state.user.org).update({
										Devices: firebase.firestore.FieldValue.arrayUnion(id),
									}).then(this.getDevices());
									this.toast('Device added', 'is-success');
								}
							} else {
								this.toast('This device does not exist', 'is-warning');
							}
						});
					}
				}
            });
		},
		select(device) {
			this.$store.state.selected = device;
			this.$store.state.page = 'live';
			this.$router.push('live')
		}
	}
}
</script>

<style scoped>
.header {
	height: 60px;
	width: 100%;
}
.all-devices .button {
	color: white;
	font-size: 14px;
	border-radius: 20px;
	border: none;
	background-color: var(--dark);
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.add-device .button {
	color: black;
	font-size: 14px;
	border-radius: 20px;
	border: none;
	background-color: white;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.add-grid .button {
	color: black;
	font-size: 14px;
	border-radius: 20px;
	border: none;
	background-color: white;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.grid-container {
	float: left;
	display: grid;
	width: 100%;
	grid-column-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 0.3fr)); 
}
.thumb {
	border: 3px solid transparent;
}
.thumb:hover {
	border-radius: 8px;
	border: 3px solid var(--select);
}
.bottom-wrapper {
	/* background-color: white; */
	overflow: hidden;
	padding: 10px;
}
</style>
