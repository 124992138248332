<template>
    <b-sidebar
		position="static"
		:reduce="true"
		type="is-dark"
		:fullheight="true"
		v-model="$store.state.sidebarOpen"
		>
		<div class="p-1">
            <div class="block" id="dashboard" @click="route($event)" style="margin-top: 10px;">
			    <img src="../assets/periscope.png" width="40"/>
                <div style="color: white; font-weight: bold">Fathom</div>
			</div>
			<div class="block" :class="{active: $store.state.page === 'dashboard'}" id="dashboard" @click="route($event)">
                <b-icon icon="home-variant-outline"></b-icon>
                <div>Home</div>
            </div>
            <div class="block" :class="{active: $store.state.page === 'live'}" id="live" @click="route($event)">
                <b-icon icon="flash"></b-icon>
                <div>Live</div>
            </div>
            <div class="block" :class="{active: $store.state.page === 'grid'}" id="grid" @click="route($event)">
                <b-icon icon="grid"></b-icon>
                <div>Grid</div>
            </div>
            <!-- <div class="block" :class="{active: $store.state.page === 'devices'}" id="devices" @click="route($event)">
                <b-icon icon="database-outline"></b-icon>
                <div>Devices</div>
            </div> -->
            <div class="block" :class="{active: $store.state.page === 'admin'}" id="admin" @click="route($event)">
                <b-icon icon="cog-outline"></b-icon>
                <div>Admin</div>
            </div>
            <div class="block" @click="logout">
                <b-icon icon="logout"></b-icon>
                <div>Logout</div>
            </div>
		</div>
	</b-sidebar>
</template>

<script>
import firebase from 'firebase/app'

export default {
    name: 'Sidebar',
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {  
        route(event) {
            if (this.$store.state.page !== event.currentTarget.id) {
                this.$store.state.page = event.currentTarget.id;
                this.$router.push(`/${event.currentTarget.id}`);
            }
        },
        logout() {
            this.$store.state.user = null;
            this.$store.state.page = 'dashboard';
            firebase.auth().signOut().then(() => {
					this.$router.push('/login')
				}).catch((error) => {
					console.log('Logout error: ', error);
			});
        }   
    }
}
</script>

<style scoped>

.b-sidebar {
   /* color: $gray; */
   font-size: 12px;
   min-height: 100vh;
}

.block:hover {
    /* background-color: $gray-dark; */
    border-radius: 5px;
    cursor: pointer;
    color: white;
}
.active {
    /* background-color: $gray-dark; */
    border-radius: 5px;
    cursor: pointer;
    color: white;
}
</style>

