<template>
  	<div class="wrapper"> 
		<div class="tables-wrapper">
			<div class="cameras">
				<div class="table-name">
					<p>Cameras</p>
				</div>
				<div class="tables">
					<b-table
						:data="cameras_table"
						:selected.sync="selected"
						:scrollable="false"
						:mobile-cards="true"
						hoverable>

						<b-table-column field="status" label="">
							<div class="blob-wrapper">
								<div class="blob"></div>
							</div>
						</b-table-column>

						<b-table-column field="name" label="Name" v-slot="props" width="150">
							<div>
								<div class="name">{{ props.row.name }}</div>
								<div class="location">{{ props.row.location}}</div>
							</div>
						</b-table-column>

						<b-table-column field="light" label="Linked Light" v-slot="props">
							<div class="table-element" v-if="props.row.light !== 'none'">
								<b-icon icon="lightbulb-outline" size="is-small" style="color: #696969"></b-icon>{{ props.row.light }}
							</div>
						</b-table-column>

						<b-table-column field="voltage" label="Voltage (V)" v-slot="props">
							<div class="table-element">
								<b-icon icon="flash" size="is-small" style="color: #696969"></b-icon>{{ props.row.voltage }}
							</div>
						</b-table-column>

						<b-table-column field="current" label="Current (mA)" v-slot="props">
							<div class="table-element">
								<b-icon icon="current-dc" size="is-small" style="color: #696969"></b-icon>{{ props.row.current }}
							</div>
						</b-table-column>

						<b-table-column field="humidity" label="Humidity (%)" v-slot="props">
							<div class="table-element">
								<b-icon icon="water-percent" size="is-small" style="color: #696969"></b-icon>{{ props.row.humidity }}
							</div>
						</b-table-column>

						<b-table-column field="temperature" label="Temp (°C)" v-slot="props">
							<div class="table-element">
								<b-icon icon="thermometer" size="is-small" style="color: #696969"></b-icon>{{ props.row.temperature }}
							</div>
						</b-table-column>
					</b-table>
				</div>
			</div>
			<div class="lights">
				<div class="table-name">
					<p>Lights</p>
				</div>
				<div class="tables">
					<b-table
						:data="lights_table"
						:selected.sync="selected"
						:scrollable="false"
						:mobile-cards="true"
						hoverable>

						<b-table-column field="status" label="">
							<div class="blob-wrapper">
								<div class="blob"></div>
							</div>
						</b-table-column>

						<b-table-column field="name" label="Name" v-slot="props" width="150">
							<div>
								<div class="name">{{ props.row.name }}</div>
								<div class="location">{{ props.row.location}}</div>
							</div>
						</b-table-column>

						
						<b-table-column field="light" label="Linked Light" :th-attrs="lightThAttrs">
							<div class="table-element">
							</div>
						</b-table-column>

						<b-table-column field="voltage" label="Voltage (V)" v-slot="props">
							<div class="table-element">
								<b-icon icon="flash" size="is-small" style="color: #696969"></b-icon>{{ props.row.voltage }}
							</div>
						</b-table-column>

						<b-table-column field="current" label="Current (mA)" v-slot="props">
							<div class="table-element">
								<b-icon icon="current-dc" size="is-small" style="color: #696969"></b-icon>{{ props.row.current }}
							</div>
						</b-table-column>

						<b-table-column field="humidity" label="Humidity (%)" v-slot="props">
							<div class="table-element">
								<b-icon icon="water-percent" size="is-small" style="color: #696969"></b-icon>{{ props.row.humidity }}
							</div>
						</b-table-column>

						<b-table-column field="temperature" label="Temp (°C)" v-slot="props">
							<div class="table-element">
								<b-icon icon="thermometer" size="is-small" style="color: #696969"></b-icon>{{ props.row.temperature }}
							</div>
						</b-table-column>
					</b-table>
				</div>
			</div>
		</div>
		<div class="selected-wrapper" v-if="selected">
			<div class="box">
				<div>
					{{ featured.name }}
				</div>
				<div class="featured-image">
					<img src='@/assets/images/octo.png'>
				</div>
			</div>
			<div class="details left">
				<div class="line">
					Name:
				</div>
				<div class="line">
					Location:
				</div>
				<div class="line">
					Device Type:
				</div>
				<div class="line">
					IP Address:
				</div>
				<div class="line">
					MAC:
				</div>
				<div class="line" v-if="featured.type === 'camera'">
					Linked Light:
				</div>
			</div>
			<div class="details right">
				<div class="line">
					{{ featured.name }} <b-icon @click.native="nameChange(featured)" icon="pencil" size="is-small" style="cursor: pointer"></b-icon>
				</div>
				<div class="line">
					{{ featured.location ?  featured.location : 'not set'}}  <b-icon @click.native="locationChange(featured)" icon="pencil" size="is-small" style="cursor: pointer"></b-icon>
				</div>
				<div class="line">
					{{ featured.type }}
				</div>
				<div class="line">
					{{ featured.address }}
				</div>
				<div class="line">
					{{ featured.mac }}
				</div>
				<div class="line" v-if="featured.type === 'camera'">
					<b-select placeholder="Select a light" size="is-small" v-model="featured.light" @input="changeLight">
						<option value="none">None</option>
						<option
							v-for="light in lights"
							:value="light.name"
							:key="light.id">
							{{ light.name }}
						</option>
            		</b-select>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Devices',
	data() {
		return {
			selected: null,
			cameras: [],
			lights: [],
			devices: null,
		}
	},
	mounted() {
		this.getDevices();
	},
	computed: {
		cameras_table() {
            let data = [];
            this.cameras.forEach((cam, index) => {
                data.push({
                    'id': index,
                    'name': cam.name,
					'location': cam.location,
                    'voltage': cam.data.voltage.toFixed(1),
					'current': cam.data.current.toFixed(1),
					'humidity': cam.data.humidity.toFixed(1),
					'temperature': cam.data.temperature.toFixed(1),
					'type': 'camera',
					'light': cam.light,
                });
            })
            return data
        },
		lights_table() {
            let data = [];
            this.lights.forEach((light, index) => {
                data.push({
                    'id': index,
                    'name': light.name,
					'location': light.location,
                    'voltage': light.data.voltage.toFixed(1),
					'current': light.data.current.toFixed(1),
					'humidity': light.data.humidity.toFixed(1),
					'temperature': light.data.temperature.toFixed(1),
					'type': 'light',
                });
            })
            return data
        },
		featured() {
			if (this.selected.type === 'camera') {
				return this.cameras[this.selected.id]
			} else 
				return this.lights[this.selected.id]
		}
	},
	methods: {
		lightThAttrs(column) {
			return column.label === 'Linked Light' ? {
				style: {color: 'white'}
			} : null
		},
		getDevices() {	
			console.log('getDevices')
			fetch('/api/devices').then(res => res.json()).then(devices => {
				this.devices = devices;
				this.cameras = [];
				this.lights = [];
				this.devices.forEach((device, index) => {
					fetch(`/api/data?address=${device.address}`).then(res => res.json()).then(data => {
						device.data = data.sensorData;
						if (device.type === 'camera') {
							if (!device.light) { device.light = 'none'; }
							this.cameras.push(device)
						} else {
							this.lights.push(device)
						}
					});
				});
				this.selected = this.cameras_table[0];
			});
		},
		nameChange(featured) {
			this.$buefy.dialog.prompt({
                message: `Enter new device name`,
                inputAttrs: {
                    placeholder: featured.name,
                    maxlength: 20
                },
                trapFocus: true,
				closeOnConfirm: false,
                onConfirm: (value, {close}) => {
					if (this.cameras.filter(cam => { return cam.name === `${value}` }).length > 0 || this.lights.filter(light => { return light.name === `${value}` }).length > 0) {
						this.$buefy.toast.open({message: `Name already exists`, type: 'is-danger'})
					} else {
						this.$buefy.toast.open(`Name change is sending...`);
						let timeout = setTimeout(() => {
							this.$buefy.toast.open({message: `Name change failed`, type: 'is-danger'})
						}, 3000)
						if (featured.type === 'camera') {
							fetch(`/api/config?address=${this.cameras[this.selected.id].address}&name=${value}`)
								.then(res => res.json()).then(res => {
									if (res.status === 'success') {
										clearTimeout(timeout)
										this.cameras[this.selected.id].name = `${value}`;
										this.$buefy.toast.open({message: `Name change success!`, type: `is-success`});
										close()
									} else {
										clearTimeout(timeout)
										this.$buefy.toast.open({message: `Name change failed`, type: 'is-danger'});
									}
								});
						} else {
							this.cameras.filter(cam => {
								if (cam.light === this.lights[this.selected.id].name) {
									cam.light = `${value}`;
								}
							});
							fetch(`/api/config?address=${this.lights[this.selected.id].address}&name=${value}`)
								.then(res => res.json()).then(res => {
									if (res.status === 'success') { 
										clearTimeout(timeout)
										this.lights[this.selected.id].name = `${value}`;
										this.$buefy.toast.open({message: `Name change success!`, type: `is-success`});
										close()
									} else {
										clearTimeout(timeout)
										this.$buefy.toast.open({message: `Name change failed`, type: 'is-danger'});
									}
								});
						}
					}
				}
            });
		},
		locationChange(featured) {
			this.$buefy.dialog.prompt({
                message: `Enter new location`,
                inputAttrs: {
                    placeholder: featured.location,
                    maxlength: 30
                },
                trapFocus: true,
				closeOnConfirm: false,
                onConfirm: (value, {close}) => {
					this.$buefy.toast.open(`Location change is sending...`);
					let timeout = setTimeout(() => {
                        this.$buefy.toast.open({message: `Location change failed`, type: 'is-danger'})
                    }, 3000)
					if (featured.type === 'camera') {
						fetch(`/api/config?address=${this.cameras[this.selected.id].address}&location=${value}`)
							.then(res => res.json()).then(res => {
								if (res.status === 'success') {
									clearTimeout(timeout)
									this.cameras[this.selected.id].location = `${value}`;
									this.$buefy.toast.open({message: `Location change success!`, type: `is-success`});
									close()
								} else {
									clearTimeout(timeout)
									this.$buefy.toast.open({message: `Location change failed`, type: 'is-danger'});
								}
							});
					} else {
						fetch(`/api/config?address=${this.lights[this.selected.id].address}&location=${value}`)
							.then(res => res.json()).then(res => {
								if (res.status === 'success') { 
									clearTimeout(timeout)
									this.lights[this.selected.id].location = `${value}`;
									this.$buefy.toast.open({message: `Location change success!`, type: `is-success`});
									close()
								} else {
									clearTimeout(timeout)
									this.$buefy.toast.open({message: `Location change failed`, type: 'is-danger'});
								}
							});
					}
				}
            });
		},
		changeLight() {
			let timeout = setTimeout(() => {
				this.$buefy.toast.open({message: `Light link failed`, type: 'is-danger'});
			}, 3000)
			fetch(`/api/config?address=${this.cameras[this.selected.id].address}&light=${this.featured.light}`)
				.then(res => res.json()).then(res => {
					if (res.status === 'success') {
						clearTimeout(timeout)
						this.$buefy.toast.open({message: `Light link success!`, type: 'is-success'});
					} else {
						clearTimeout(timeout)
						this.$buefy.toast.open({message: `Light link failed`, type: 'is-danger'});
						this.getDevices();
					}
				});
		},
	}
}
</script>

<style scoped>
.invisible {
	color: red;
}
.wrapper {
	width: 100%;
	margin: 20px;
	padding-right: 20px;
}
.tabke-name {
	margin: 140px;
}
.lights {
	margin-top: 40px;
}
.tables {
	text-align: left;
	font-size: 12px;
	cursor: pointer;
}
.table-element {
	padding-top: 10px;
}
.table-name {
	text-align: left;
	font-weight: bold;
	padding-bottom: 10px;
	padding-left: 5px;

}
.tables-wrapper {
	float: left;
	width: 60%;
}

.selected-wrapper {
	float: left;
	text-align: left;
	width: 40%;
	font-size: 12px;
}
.box {
	margin: 20px;
	margin-top: 35px;
	/* color: $gray-dark; */
	font-size: 16px;
	font-weight: bold;
	border-radius: 10px;
}
.details {
	font-size: 14px;
}
.left {
	float: left;
	text-align: right;
	width: 50%;
	padding-right: 5px;
	/* color: $gray; */
	font-weight: bold;
}
.right {
	float: left;
	text-align: left;
	width: 50%;
	padding-left: 5px;
	/* color: $gray-dark; */
	font-weight: bold;
}
.featured-image {
	width: 100px;
	margin: auto;
}
.line {
	margin-top: 10px;
}
.name {
	/* color: $gray-dark; */
	font-weight: bold;
	/* font-size: 16px; */
}
.location {
	/* color: $gray; */
	font-weight: bold;
	font-size: 12px;
}
.blob-wrapper {
	position: relative;
	height: 30px;
	padding-left: 10px;
}
.blob {
    background: rgba(0, 227, 23, 1);
    box-shadow: 0 0 0 0 rgba(0, 227, 23, 1);
    border-radius: 50%;
    margin: 0px;
    height: 7px;
    width: 7px;
	position: absolute;
  	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

</style>
