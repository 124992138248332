import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)

Vue.config.productionTip = false

import firebase from "firebase/app";
import 'firebase/firestore'
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBQuf7Yq3UFQXxmZYBaFQkvQkbmQE8XXok",
  authDomain: "coral-vision-282617.firebaseapp.com",
  projectId: "coral-vision-282617",
  storageBucket: "coral-vision-282617.appspot.com",
  messagingSenderId: "419397085366",
  appId: "1:419397085366:web:c454b95f854c893c7cde17",
  measurementId: "G-J64Z9RTZ93"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export const db = firebase.firestore()

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      // store.dispatch('setUser', user);
      unsubscribe();
      resolve(user);
    }, reject);
  })
};

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
