<template>
  	<div> 
		<div class="overlay">
            <nav class="level">
                <div class="level-left">
                    <div class="level-item status-wrapper">
                        <!-- <div class="blob"></div> -->
                        <div class="name">{{ id }}</div>
                    </div>
                </div>
            </nav>
        </div>
		<div v-show="!loading" class="time">
            <nav class="level">
                <div class="level-left">
                    <div class="level-item status-wrapper">
						<b-icon icon="clock" size="is-small"></b-icon>
                        <div class="name"> {{ time }}</div>
                    </div>
                </div>
            </nav>
        </div>
        <b-loading :is-full-page="false" v-model="loading" :can-cancel="true"></b-loading>
		<img class="thumb" :src="src" width=1920 height=1080>
	</div>
</template>

<script>
import { db } from '../main'

export default {
	name: 'Thumb',
    props: ['id'],
	data() {
		return {
			src: null,
			time: '',
      		loading: true,
		}
	},
	mounted() {
		this.getThumb();
	},
	methods: {
        getThumb() {
            db.collection('Devices').doc(this.id).get().then(doc => {
                let date = new Date();
                let nearest_ten = Math.ceil(date.getHours() / 10) * 10; // nearest 10 minutes for thumbnail
				this.time = date.toString().split(' ').slice(0,5).join(' ');
                this.src = `https://${doc.data().balenaID}.balena-devices.com/thumbnail?${nearest_ten}`;
				// TODO: Return image with timestamp in proper callback
				setTimeout(() => {
					this.loading = false;
				}, 3000);
            });
        },
	},
}
</script>

<style scoped>
.thumb {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
}
.overlay {
	position: absolute;
	top: 10px;
	left: 10px;
	color: white;
	font-weight: bold;
	z-index: 1;
}
.time {
	position: absolute;
	bottom: 10px;
	left: 10px;
	color: white;
	font-weight: normal;
	z-index: 1;
}
.name {
    padding-left: 2px;
}
.status-wrapper {
	background-color: var(--dark);
	border-radius: 10px;
	font-size: 12px;
	padding: 2px 10px 2px 5px;
}
.blob {
    background: rgba(0, 227, 23, 1);
    box-shadow: 0 0 0 0 rgba(0, 227, 23, 1);
    border-radius: 50%;
    margin: 6px;
    height: 5px;
    width: 6px;
    transform: scale(1);
    animation: pulse-red 5s infinite;
    margin-right: 0.5em;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 227, 23, 0.7);
  }
  
  30% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 227, 23, 0);
  }
  
  40%, 100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 227, 23, 0);
  }
}
</style>
