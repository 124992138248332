<template>
	<div class="wrapper">
        <div class="wrapper-tile">
            <div class="box">
                <h1 class="fathom-title">Periscope</h1>
                <p style="font-size: 16px;">Fathom Ocean</p>
                <div class="block" id="dashboard" style="margin-top: 30px;">
                    <img src="../assets/periscope.png" width="80"/>
                </div>
                <section>
                    <b-field>
                        <b-input type="username" placeholder="email"
                            v-model="form.email">
                        </b-input>
                    </b-field>
                    <b-field>
                        <b-input type="password" placeholder="password"
                            v-model="form.password"
                            @keyup.native.enter="login">
                        </b-input>
                    </b-field>
                    <section style="text-align: center">
                        <b-button @click="login" type="is-primary">Login</b-button>
                        <div v-if="error" class="alert alert-danger" style="margin-top: 15px;">{{error}}</div>
                    </section>
                </section>
                <p class="sign-up">Don't have an account? Click here to <router-link to="/signup" style="color: black; font-weight: bold">Sign Up.</router-link></p>
            </div>
        </div>
    </div>	
</template>

<script>
import firebase from 'firebase/app'
import { db } from '../main'

export default {
    name: 'Login',
    data() {
        return {
            form: {
                username: '',
                password: '',
            },
            error: null,
        }
    },
    methods: {
        login() {
            firebase
                .auth()
                .signInWithEmailAndPassword(this.form.email, this.form.password)
                .then(data => {
                    db.collection('Users').doc(data.user.email).get().then(doc => {
                        this.$store.state.user = {
                            name: doc.data().name,
                            email: doc.data().email,
                            org: doc.data().org,
                            admin: doc.data().admin,
                            UID: doc.data().UID,
                            // data: data,
                        }
                        this.$router.replace({ name: 'Home' });
                    });
                })
                .catch(error => {
                    this.error = error.message;
                });
		},
    }
};
</script>

<style scoped>

.wrapper {
    width: 100%;
    height: 100vh;
    background: -webkit-linear-gradient(#0580D9 , #1A1A1A);
}
.wrapper-tile {
    margin: auto;
    padding-top: 70px;
    max-width: 400px;
}
.fathom-title {
    font-weight: bolder;
    font-size: 22px;
}
.sign-up {
    text-align: left;
    margin-top: 30px;
}
.button {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
.input:active {
	border-color: var(--select) !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
}
</style>