<template>
  	<div style="position: relative">
        <div class="overlay">
            <nav class="level">
                <div class="level-left">
                    <div class="level-item status-wrapper">
                        <div class="blob"></div>
                        <div class="name">{{ id }}</div>
                    </div>
                </div>
            </nav>
        </div>
		<div v-show="type" class="type">
            <nav class="level">
                <div class="level-left">
                    <div class="level-item status-wrapper">
						<b-icon v-if="type==='cloud'" icon="cloud" size="is-small"></b-icon>
						<b-icon v-if="type==='local'" icon="lan-connect" size="is-small"></b-icon>
                        <div class="name">HD - {{ type }}</div>
                    </div>
                </div>
            </nav>
        </div>
		<div v-show="recording" class="record">
            <nav class="level">
                <div class="level-left">
                    <div class="level-item status-wrapper">
						<div class="rec-blob"></div>
                        <div class="name">{{ time }}</div>
                    </div>
                </div>
            </nav>
        </div>
		<div v-show="timestamp" class="time">
            <nav class="level">
                <div class="level-left">
                    <div class="level-item status-wrapper">
						<b-icon icon="clock" size="is-small"></b-icon>
                        <div class="name">{{ timestamp }}</div>
                    </div>
                </div>
            </nav>
        </div>
		<b-loading :is-full-page="false" v-model="loading" :can-cancel="true"></b-loading>
        <video class="grid-player" 
            :id="id" 
            autoplay muted
            width="1920" height="1080">
        </video>
	</div>
</template>

<script>
import { db } from '../main'
import Janus from '../Janus';

export default {
	name: 'GridPlayer',
    props: ['id', 'recording'],
	data() {
		return {
			janus: null,
			streaming: null,
			streams: [],
			recorder: null,
			recordedBlobs: [],
			type: '',
			loading: true,
			time: '00:00',
			timer: null,
			timestamp: null,
			clock: null,
		}
	},
	beforeDestroy() {
		this.streaming.hangup();
	},
	mounted() {
		db.collection('Devices').doc(this.id).get().then(doc => {
			let server = `https://${doc.data().balenaID}.balena-devices.com/janus`;
			this.initJanus(server);
		})
	},
	watch: {
		recording: function(state) {
			state ? this.startRecord() : this.stopRecord();
		}
	},
	methods: {
		startTimer() {
			let seconds = 0;
			this.timer = setInterval(() => {
				++seconds;
				let secs = seconds % 60;
				let mins = parseInt(seconds / 60);
				mins = (mins < 10) ? `0${mins}` : mins;
				secs = (secs < 10) ? `0${secs}` : secs;
				this.time = `${mins}:${secs}`;
			}, 1000);
		},
		startClock(ts) {
			this.clock = setInterval(() => {
				let seconds = (ts.getSeconds() < 10 ? `0${ts.getSeconds()}` : ts.getSeconds())
				let minutes = (ts.getMinutes() < 10 ? `0${ts.getMinutes()}` : ts.getMinutes())
				this.timestamp = ts.toString().split(' ').slice(0, 4).join(' ') + " " +  ts.getHours( )+ ":" +  minutes + ":" +  seconds;
				ts.setSeconds(ts.getSeconds() + 1);
			}, 1000);
		},
		startRecord() {
			this.startTimer();
			this.recordedBlobs = [];
			let options = {mimeType: 'video/webm;codecs=vp9'};
			if (!MediaRecorder.isTypeSupported(options.mimeType)) {
				console.log(options.mimeType + ' is not Supported');
				options = {mimeType: 'video/webm;codecs=vp8'};
				if (!MediaRecorder.isTypeSupported(options.mimeType)) {
					console.log(options.mimeType + ' is not Supported');
					options = {mimeType: 'video/webm;codecs=h264'};
					if (!MediaRecorder.isTypeSupported(options.mimeType)) {
						console.log(options.mimeType + ' is not Supported');
						options = {mimeType: 'video/webm'};
						if (!MediaRecorder.isTypeSupported(options.mimeType)) {
							console.log(options.mimeType + ' is not Supported');
							options = {mimeType: ''};
						}
					}
				}
			}
			try {
				this.recorder = new MediaRecorder(this.streams[1], options);
			} catch (e) {
				console.error('Exception while creating MediaRecorder: ' + e);
				alert('Exception while creating MediaRecorder: ' + e + '. mimeType: ' + options.mimeType);
				return;
			}
			this.recorder.onstop = this.handleStop;
    		this.recorder.ondataavailable = this.handleDataAvailable;
			this.recorder.onwarning = (e) => {
				console.log('Warning: ' + e);
			};
			this.recorder.start();
			console.log('MediaRecorder started', this.recorder);
		},
		stopRecord() {
			this.time = '00:00';
			this.seconds = 0;
			clearInterval(this.timer);
			this.recorder.stop();
		},
		handleStop(event) {
			console.log('Recorder stopped: ', event);
			this.recorder = null;
			let superBuffer = new Blob(this.recordedBlobs, {type: 'video/webm'});
			const h = this.$createElement
			const vnode = h('div', { class: "" }, [
                    h('video', { attrs: { src: URL.createObjectURL(superBuffer), id: 'recorded-video', controls: true }}),
                ])
			this.$buefy.modal.open({
				content: [ vnode ]
			});
		},
		handleDataAvailable(event) {
			if (event.data && event.data.size > 0) {
				this.recordedBlobs.push(event.data);
			}
		},
		snapshot() {
			console.log("Taking still photo...");
			const h = this.$createElement
			const vnode = h('div', { class: "" }, [
				h('canvas', { attrs: { id: "snapshot" }}),
				])
			this.$buefy.modal.open({
				content: [ vnode ]
			});
			let imageCapture = new ImageCapture(this.streams[1].getVideoTracks()[0]);
			imageCapture.grabFrame().then(bitmap => {
				let canvas =  document.getElementById('snapshot');
				this.drawCanvas(canvas, bitmap);
			})
		},
		drawCanvas(canvas, img) {
			canvas.width = img.width; 
			canvas.height = img.height;
			let ratio = Math.min(canvas.width / img.width, canvas.height / img.height);
			let x = (canvas.width - img.width * ratio) / 2;
			let y = (canvas.height - img.height * ratio) / 2;
			canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
			canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height,
				x, y, img.width * ratio, img.height * ratio);
		},
		startStream(id) {
			let body = { request: "watch", id: id };
			this.streaming.send({ message: body });
			this.getStreamInfo(id);
		},
		stopStream(id) {
			let body = { request: "stop" };
			this.streaming.send({ message: body });
			this.streaming.hangup();
		},
		getStreamInfo(id) {
			let body = { request: "info", id: id };
			this.streaming.send({
				message: body, success: (result) => {
					console.log('getStreamInfo: ', result)
				}
			});
		},
		updateStreamsList() {
			let body = { request: "list" };
			this.streaming.send({
				message: body, success: (result) => {
					if (result["list"]) {
						this.streams = result["list"];
						Janus.log("Got a list of available streams: ", this.streams);
						this.startStream(1);
					}
				}
			});
		},
		initJanus(server) {
			Janus.init({
				debug: "false", callback: () => {
					this.janus = new Janus({
						server: server,
						iceServers: 
							[{url: 'turn:104.154.194.9:3478',
								credential: 'admin',
								username: 'admin'
							}],
						success: () => {
							this.janus.attach({
								plugin: "janus.plugin.streaming",
								opaqueId: Janus.randomString(12),
								success: (pluginHandle) => {
									this.streaming = pluginHandle;
									Janus.log("Plugin attached! (" + this.streaming.getPlugin() + ", id=" + this.streaming.getId() + ")");
									this.updateStreamsList();
								},
								error: (error) => {
									Janus.error("  -- Error attaching plugin... ", error);
								},
								iceState: (state) => {
									Janus.log("ICE state changed to " + state);
									if (state === 'connected') {
										console.log('connected')
										this.streaming.webrtcStuff.pc.getReceivers()[0].getStats().then(stats => {
											for (let value of stats.values()) {
												if (value.type === 'inbound-rtp') {
													this.startClock(new Date(value.timestamp));
												}
											}
											
										});
										this.streaming.webrtcStuff.pc.getReceivers().map(r => {
										const kindOfTrack = r.track.kind;
										if (r.transport) {
											const iceTransport = r.transport.iceTransport;
											const selectedCandidatePair = iceTransport.getSelectedCandidatePair();
											if (selectedCandidatePair.local.type === 'host') {
												this.type = "local";
											} else {
												this.type = "cloud";
											}
										} else {
											// retry at some time later
										}
									});
									} else if (state === 'disconnected') {
										console.log('disconnected')
										clearInterval(this.clock)
										this.clock = null;
										this.timestamp = null;
									}
								},
								webrtcState: (on) => {
									Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
									if (on) {
										this.loading = false;
									}
								},
								onmessage: (msg, jsep) => {
									Janus.log("Got a message: ", msg);
									if (msg.result.status === 'started') {
									}
									if (jsep) {
										Janus.log("Handling SDP: ", jsep);
										this.streaming.createAnswer({
											jsep: jsep,
											media: { audioSend: false, videoSend: false},
											success: (jsep) => {
												Janus.log("Got SDP!", jsep);
												let body = { request: "start" };
												this.streaming.send({ message: body, jsep: jsep });
											},
											error: (error) => {
												Janus.log("WebRTC error... " + error.message);
											}
										});
									}
								},
								onremotetrack: (track, mid, on) => {
									console.log('onremotetrack: ', track)
									let stream = new MediaStream();
									this.streams.push(stream);
									stream.addTrack(track.clone());
									let el = document.getElementById(this.id);
									Janus.attachMediaStream(el, stream);
								},
								ondataopen: (data) => {
									Janus.log("The DataChannel is available!", data);
								},
								ondata: (data) => {
									Janus.debug("We got data from the DataChannel!", data);
								},
								oncleanup: () => {
									clearInterval(this.clock)
									this.clock = null;
									this.timestamp = null;
									Janus.log(" ::: Got a cleanup notification :::");
								}
							});
						},
						error: (error) => {
							Janus.error(error);
							console.log(error)
						},
						destroyed: () => {
							console.log('destoryed')
						}
					});
				}
			});
        }
	}
}
</script>

<style scoped>
.grid-player {
    border-radius: 5px;
}
.overlay {
	position: absolute;
	top: 10px;
	left: 10px;
	color: white;
	font-weight: bold;
}
.type {
	position: absolute;
	bottom: 10px;
	right: 10px;
	color: white;
	font-weight: bold;
}
.record {
	position: absolute;
	top: 10px;
	right: 10px;
	color: white;
	font-weight: bold;
}
.time {
	position: absolute;
	bottom: 10px;
	left: 10px;
	color: white;
	font-weight: normal;
	z-index: 1;
}
.name {
	padding-left: 2px;
}
.status-wrapper {
	background-color: var(--dark);
	border-radius: 10px;
	font-size: 12px;
	padding: 2px 10px 2px 10px;
}

.blob {
    background: rgba(0, 227, 23, 1);
    box-shadow: 0 0 0 0 rgba(0, 227, 23, 1);
    border-radius: 50%;
    margin: 6px;
    height: 5px;
    width: 6px;
    transform: scale(1);
    animation: pulse-green 5s infinite;
    margin-right: 0.5em;
}
@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 227, 23, 0.7);
  }
  
  30% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 227, 23, 0);
  }
  
  40%, 100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 227, 23, 0);
  }
}

.rec-blob {
    background: rgba(255, 0, 0, 1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
    border-radius: 50%;
    margin: 6px;
    height: 5px;
    width: 6px;
    transform: scale(1);
    animation: pulse-red 1s infinite;
    margin-right: 0.5em;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  
  30% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  
  40%, 100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

</style>
