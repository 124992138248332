<template>
    <div style="margin: 10px;">
        <div class="grid-container"> 
            <div v-for="(device, index) in $store.state.devices" :key="index">
                <Player class="thumb" :id="device" @click.native="select(device)" />
            </div>
        </div>
    </div>
</template>

<script>
import Player from '@/components/Player.vue'

export default {
	name: 'Grid',
    components: {
        Player,
    },
	data() {
		return {
			
		}
	},
	mounted() {
		
	},
	methods: {
		select(device) {
			this.$store.state.selected = device;
			this.$store.state.page = 'live';
			this.$router.push('live')
		}	
	}
}
</script>

<style scoped>
.grid-container {
	float: left;
	width: 100%;
	display: grid;
	grid-column-gap: 5px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
	cursor: pointer;
}

</style>
